export const routeList = [
  {
    label: "Contents",
    icon: "TableOfContents", // トップレベルのアイコンを追加
    description: "ストラボのコンテンツ",
    items: [
      {
        href: "/study",
        label: "Study",
        description: "構造設計者のスタディ",
        icon: "NotebookPen",
      },
      {
        href: "/book",
        label: "Book",
        description: "建築の書籍",
        icon: "Book",
      },
    ],
  },
  {
    label: "Resources",
    icon: "Archive", // トップレベルのアイコンを追加
    description: "構造設計のリソース",
    items: [
      {
        href: "/resources/keisan",
        label: "Keisan",
        description: "構造計算書のリソース",
        icon: "Calculator",
      },
      {
        href: "/resources/zumen",
        label: "Zumen",
        description: "構造図のリソース",
        icon: "FileText",
      },
      {
        href: "/resources/database",
        label: "Database",
        description: "構造設計知識のリソース",
        icon: "Database",
      },
    ],
  },
  {
    label: "Links",
    icon: "Link", // トップレベルのアイコンを追加
    description: "構造設計のリソース",
    items: [
      {
        href: "/notice",
        label: "Updates",
        description: "お知らせ・サイト更新情報",
        icon: "BellRing",
      },
      {
        href: "/contact",
        label: "Contact",
        description: "お問い合わせ",
        icon: "Mail",
      },
    ],
  },
  {
    label: "Legal",
    icon: "Scale", // トップレベルのアイコンを追加
    description: "利用規約とプライバシーポリシー",
    items: [
      {
        href: "/terms",
        label: "利用規約",
        description: "サービス利用規約",
        icon: "Handshake",
      },
      {
        href: "/privacy-policy",
        label: "プライバシーポリシー",
        description: "個人情報の取り扱い",
        icon: "Shield",
      },
      {
        href: "/transaction-law",
        label: "特商法表示",
        description: "特定商取引法に基づく表示",
        icon: "BadgeJapaneseYen",
      },
    ],
  },
]

export const SNSList = [
  {
    href: "https://github.com/StructureBox",
    label: "GitHub",
    svg: `
      <svg
        class="h-5 w-5"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
      </svg>
    `,
  },
  {
    href: "https://jp.pinterest.com/structurebox",
    label: "Pinterest",
    svg: `
      <svg
        class="h-5 w-5"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z" />
      </svg>
    `,
  },
  {
    href: "https://x.com/StructureBox",
    label: "X",
    svg: `
      <svg
        class="h-5 w-5"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
      </svg>
    `,
  },
]
